import React, {useState, useContext } from "react";
import { TbHistoryToggle } from "react-icons/tb";
import { NavLink, useNavigate } from "react-router-dom";
import { IoMdPerson } from "react-icons/io";
import { FcCallTransfer } from "react-icons/fc";
import { UserContext } from "../../context/UserContext";
import {
  MdEdit,
  MdOutlinePhoneInTalk,
  MdOutlinePhoneMissed,
} from "react-icons/md";
import { BsDatabase } from "react-icons/bs";
import PendingTasksReminder from "../ToDoTasks/pendingTasksReminder";
import { getCall, postCall } from "../../utils/methods";
import { config } from "../../config";
import { bottomAlert } from "../../utils/toastAlerts";
import  AcceptInvitation  from "../../components/Invitation/AcceptInvitation";
import "./utilities.scss";
import { BiSolidNotification } from "react-icons/bi";

function Utilities() {
  const { User } = useContext(UserContext);
  const agentStatus = User.agentStatus;
  const navigate = useNavigate();

  const [notificationData, setNotificationData] = useState();
  
    const handleClickOpen = () => {
         getCall({
           url: config.url.NOTIFICATIONS,
           path: `/unread_notification`,
           onSuccess: (response) => {
            if (response.data.records && response.data.records.length > 0) {
              setNotificationData(response.data.records);
            } else {
              bottomAlert("You have no invite requests.", "warning");
              setNotificationData([]); // Ensure dialog can show the empty message state
            }

           },
           onFailure: (error) => {
             bottomAlert("Notification Read Failed", "warning");
           },
         }); 
    };

    const takeAction = (id, actionLink) => {  
      postCall({
        url:  config.url.NOTIFICATIONS,
        path: `/take_action/${id}/${actionLink?.name}`,
        onSuccess: (res) => {
          setNotificationData();
          bottomAlert(actionLink.on_success, "success");
        },
        onFailure:(error) => {
          setNotificationData();
          bottomAlert(actionLink.on_failure + " " + ((error.response && error.response.data.detail) || error.toString()), "warning");
        }
      });
    };
 
   

  return (
    <div className="saleassist-dashboard-utilities">
      <div className="profile-container">
        <div className="profile-card" onClick={() => navigate("/profile")}>
        <PendingTasksReminder />

          <div className="content-wrapper">
            <span className="content-icon">
              <IoMdPerson />
            </span>
            <div className="content-text">
              <span>{User.display_name || "No name"}</span>
              <span>
                {User.email || User.mobile_number || "No email address"}
              </span>
            </div>
            <span className={`vector-shape ${agentStatus}`}></span>
          </div>
          <span className="edit-icon">
            <MdEdit />
          </span>
        </div>
      </div>

      <div className="utilities-container">
        <NavLink to="/meetings/active-request-queue" end>
          <div className="utility-card active-requests">
            <span className="card-icon ">
              <MdOutlinePhoneInTalk />
            </span>
            <span className="card-text">Active Requests</span>
          </div>
        </NavLink>

        <NavLink to="/meetings/call-transfer-logs" end>
          <div className="utility-card call-transfer">
            <span className="card-icon ">
              <FcCallTransfer className="call-transfer-icon" />
            </span>
            <span className="card-text">Call Transfer Logs</span>
          </div>
        </NavLink>

        <NavLink to="/meetings/missed-call-queue" end>
          <div className="utility-card missed-calls">
            <span className="card-icon ">
              <MdOutlinePhoneMissed className="missed-call-icon" />
            </span>
            <span className="card-text">Missed Calls</span>
          </div>
        </NavLink>

        <NavLink to="/meetings/history" end>
          <div className="utility-card history">
            <span className="card-icon">
              <TbHistoryToggle />
            </span>
            <span className="card-text">History</span>
          </div>
        </NavLink>
        <NavLink to="/to-do-tasks" end>
          <div className="utility-card to-do-tasks">
            <span className="card-icon">
              <BsDatabase />
            </span>
            <span className="card-text">To-Do Tasks</span>
          </div>
        </NavLink>
        <div onClick={handleClickOpen} style={{cursor:"pointer"}}>
          <div className="utility-card accept-invitation">
            <span className="card-icon">
              <BiSolidNotification />
            </span>
            <span className="card-text">Accept Invitation</span>
          </div>
        </div>
            {notificationData?.length > 0 && <AcceptInvitation 
              notification={notificationData} 
              takeAction={takeAction}
            />}

      </div>
    </div>
  );
}

export default Utilities;
