import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import MeetingLite from "./components/MeetingLite/MeetingLite";
import UpcomingMeetingLite from "./components/UpcomingMeetingLite/UpcomingMeetingLite";
import { getCall } from "./utils/methods";
import { bottomAlert } from "./utils/toastAlerts";
import { UserContext } from "./context/UserContext";
import { PermissionsContext } from "./context/PermissionsContext";
import { ClientContext } from "./context/ClientContext";
import { SocketContext } from "./context/SocketContext";
import { config } from "./config";
import HistoryLite from "./components/HistoryLite/HistoryLite";
import WhatsAppLeadDetails from "./components/Conversation/Leads/LeadDetails/WhatsAppLeadDetails";
import LoginError from "./components/LoginError/LoginError";
import Header from "./components/Header/Header";
import Profile from "./components/Profile/Profile";
import { io } from "socket.io-client";
import MeetingInvite from "./components/MeetingInvite/MeetingInvite";
import CallTransferLogsLite from "./components/CallTransferLogsLite";
import LiveMeeting from "./components/LiveMeeting/LiveMeeting";
import ActiveRequestsQueue from "./components/CallsQueue/ActiveRequestsQueue";
import MissedCallsQueue from "./components/CallsQueue/MissedCallsQueue";
import FooterNavigation from "./components/FooterNavigation/FooterNavigation";
import Conversation from "./components/Conversation/Conversation";
import LeadDetails from "./components/Conversation/Leads/LeadDetails/LeadDetails";
import Utilities from "./components/Utilities/Utilities";
import OutboundCalls from "./components/OutboundCalls/OutboundCalls";
import Notifications from "./components/Notifications/Notifications";
import NewMeeting from "./components/Meetings/NewMeeting/NewMeeting";
import ScheduleMeeting from "./components/Meetings/ScheduleMeeting/ScheduleMeeting";
import LiveStreaming from "./components/Meetings/LiveStreaming/LiveStreaming";
import ToDoTable from "./components/ToDoTasks/ToDoTable";
import MeetingDetails from "./components/MeetingDetails/MeetingDetails";

import "../src/styles/_index.scss";

function App() {
  const { User, setUser } = useContext(UserContext);
  const { Permissions, setPermissions } = useContext(PermissionsContext);
  const { Client, setClient } = useContext(ClientContext);
  const { setSocket } = useContext(SocketContext);
  const [isLoading, setIsLoading] = useState(true);
  const [login, setLogin] = useState(false);
  const location = useLocation();

  const initSocket = (user_id, user_email, client_id) => {
    const socket = io(config.url.SOCKET_URL, {
      query: `id=${user_id}&type=agent&uid=${user_email}&client_id=${client_id}`,
    });

    socket.on("connect", (data) => {
      console.log(data, "socket connected successfully");
      setSocket({ socket: socket });
    });
  };

  const fetchOrCreate = () => {
    getCall({
      url: config.url.USERS,
      path: "fetch_or_create",
      onSuccess: (response) => {
        setUser(response.data.user);
        setPermissions(response.data.permissions);
        setClient(response.data.client);
        initSocket(
          response.data.user.id,
          response.data.user.email,
          response.data.client.id
        );
        setIsLoading(false);
        setLogin(false);
      },
      onFailure: (error) => {
        // Need to create an endpoint to register failures
        bottomAlert(
          "Unable to authorize your identity. " +
            ((error.response && error.response.data.detail) ||
              error.toString()),
          "warning"
        );
        setIsLoading(false);
        setLogin(true);
      },
    });
  };

  useEffect(() => {
    fetchOrCreate();

    return () => {};
    // eslint-disable-next-line
  }, []);

  if (isLoading) {
    return <div className="saleassist-lite-meeting-progress-bar"></div>;
  }

  // Redirect if User or Client or Permissions are not loaded
  if (!User || !Client || !Permissions) {
    return <Navigate to="/" />;
  }

  return (
    <div className="saleassist-dashboard-lite">
      {login ? (
        <LoginError />
      ) : (
        <>
          {!location.pathname.startsWith("/meetings/lite") && <Header />}
          <Routes>
            <Route exact path="/" element={<Navigate to="/meetings/lite" />} />

            <Route
              exact
              path="/meetings/schedule"
              element={<UpcomingMeetingLite />}
            />

            <Route exact path="/meetings/lite" element={<MeetingLite />} />

            <Route exact path="/meetings/lite/:id" element={<MeetingLite />} />

            <Route
              exact
              path="/meetings/live/:id"
              element={<LiveMeeting conference />}
            />

            <Route
              exact
              path="/meetings/people/:id"
              element={<LiveMeeting />}
            />

            <Route
              exact
              path="/meetings/invite/:id"
              element={<MeetingInvite />}
            />

            <Route
              exact
              path="/meetings/view/:id"
              element={<MeetingDetails />}
            />

            <Route
              exact
              path="/meetings/edit/:id"
              element={<ScheduleMeeting isEdit />}
            />

            <Route exact path="/meetings/history" element={<HistoryLite />} />

            <Route
              exact
              path="/meetings/call-transfer-logs"
              element={<CallTransferLogsLite />}
            />

            <Route
              exact
              path="/meetings/active-request-queue"
              element={<ActiveRequestsQueue />}
            />

            <Route
              exact
              path="/meetings/missed-call-queue"
              element={<MissedCallsQueue />}
            />

            <Route
              exact
              path="/meetings/leads"
              element={<Conversation tab="1" />}
            />

            <Route exact path="/meetings/leads/:id" element={<LeadDetails />} />

            <Route
              exact
              path="/meetings/whatsapp"
              element={<Conversation tab="2" />}
            />

            <Route
              exact
              path="/meetings/whatsapp/:id"
              element={<WhatsAppLeadDetails />}
            />

            <Route exact path="/profile" element={<Profile />} />

            <Route exact path="/utilities" element={<Utilities />} />

            <Route exact path="/outbounds" element={<OutboundCalls />} />

            <Route
              exact
              path="/meetings/notifications"
              element={<Notifications />}
            />

            <Route
              exact
              path="/meetings/new/conference"
              element={<NewMeeting />}
            />

            <Route
              exact
              path="/meetings/new/schedule"
              element={<ScheduleMeeting />}
            />

            <Route
              exact
              path="/meetings/new/live-streaming"
              element={<LiveStreaming />}
            />

            <Route
              exact
              path="/to-do-tasks"
              element={<ToDoTable />}
            />
          </Routes>
          <FooterNavigation />
        </>
      )}
    </div>
  );
}

export default App;
