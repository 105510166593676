import { useContext, useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Checkbox,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { UserContext } from "../../../context/UserContext";
import { config } from "../../../config";
import { getCall, postCall, putCall } from "../../../utils/methods";
import { bottomAlert } from "../../../utils/toastAlerts";
import { validateForm } from "../shared/validateForm";
import MeetingNameAndDescription from "../shared/MeetingNameAndDescription";
import ModeratorsAndParticipants from "../shared/ModeratorsAndParticipants";
import Watermark from "../shared/Watermark";
import { roundToNearest15 } from "../../../utils/time";
import { timeZonesData } from "./timeZones";
import FormBuilder from "../shared/FormBuilder";
import { FormContext } from "../../../context/FormContext";
import "../shared/meeting.scss";

const meetingDate = new Date().toLocaleString("en-US", {
  day: "numeric",
  month: "numeric",
  year: "numeric",
});

const meetingTime = roundToNearest15(new Date()).toLocaleString("en-US", {
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});
// for the TimePicker component
const formattedMeetingTime = dayjs()
  .set("hour", dayjs(meetingTime, "h:mm A").hour())
  .set("minute", dayjs(meetingTime, "h:mm A").minute())
  .format("YYYY-MM-DDTHH:mm");

const meetingTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const registrationFormId = "schedule-meeting";
let registrationForm = '[{"type":"paragraph","subtype":"p","label":"Please fill in your details to register","access":false},{"type":"text","required":true,"label":"Name","placeholder":"Enter your name","className":"form-control","name":"name","access":false,"subtype":"text"},{"type":"text","required":true,"label":"Email","placeholder":"Enter your email","className":"form-control","name":"email","access":false,"subtype":"text"},{"type":"text","required":false,"label":"Mobile number","placeholder":"Enter your mobile number","className":"form-control","name":"mobile_number","access":false,"subtype":"text"}]';

const registrationFormFields = [];
try {
  JSON.parse(registrationForm).forEach((field) => {
    if (field.name) {
      registrationFormFields.push(
        // <option key={"registration_form_" + field.name} value={field.name}>
          field.name
        // </option>
      );
    }
  });
} catch (error) {
  console.log(error);
}

export default function ScheduleMeeting({ isEdit }) {
  const meetingId = useParams().id;
  const { User } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const { getFormData } = useContext(FormContext);
  const [isLoading, setIsLoading] = useState(false);
  const [meetingType, setMeetingType] = useState("conference");
  const [meetingName, setMeetingName] = useState("");
  const [meetingDescription, setMeetingDescription] = useState("");
  const [moderators, setModerators] = useState([User?.user_name]);

  // setting the participant value if page is redirected from conversation panel
  const [participants, setParticipants] = useState(searchParams.get('participant') ? ["+" + searchParams.get('participant').slice(1)] : []);

  const [watermark, setWatermark] = useState("");

  const [date, setDate] = useState(dayjs(meetingDate));
  const [time, setTime] = useState(dayjs(formattedMeetingTime));
  const [timezone, setTimezone] = useState(meetingTimezone);
  const [duration, setDuration] = useState(1);
  const [durationMode, setDurationMode] = useState("hr");
  const [isRegistrationForm, setIsRegistrationForm] = useState(false);
  const [uniqueFieldName, setUniqueFieldName] = useState("email");
  const navigate = useNavigate();

  const onSubmit = () => {
    if (!validateForm(meetingName, meetingDescription)) {
      return;
    }

    const data = {
      meeting_type: meetingType,
      meeting_name: meetingName,
      description: meetingDescription,
      moderators: moderators,
      participants: participants,
      watermark: watermark,
      date: `${date.month() + 1}/${date.date()}/${date.year()}`,
      time: time.format("H:mm A"),
      timezone: timezone,
      duration: duration,
      duration_mode: durationMode,
      registration_enabled: isRegistrationForm,
      registration_form: getFormData(registrationFormId),
      unique_field_name: uniqueFieldName,
    }

    setIsLoading(true);

    if(isEdit) {
      putCall({
        url: config.url.MEETINGS,
        path: meetingId,
        data: data,
        onSuccess: (response) => {
          bottomAlert(`Your ${meetingType} was re-scheduled.`, "success");
          navigate("/meetings/schedule");
        },
        onFailure: (error) => {
          bottomAlert(
            "Your meeting could not be re-scheduled. " +
              ((error.response && error.response.data.detail) ||
                error.toString()),
            "warning"
          );
          setIsLoading(false);
        },
      })
    } 
    else {
      postCall({
        url: config.url.MEETINGS,
        data: data,
        onSuccess: (response) => {
          bottomAlert(`Your ${meetingType} was scheduled.`, "success");
          navigate("/meetings/schedule");
        },
        onFailure: (error) => {
          bottomAlert(
            "Your meeting could not be created. " +
              ((error.response && error.response.data.detail) ||
                error.toString()),
            "warning"
          );
          setIsLoading(false);
        },
      });
    }
  };

  useEffect(() => {
    if(isEdit) {
      getCall({
        url: config.url.MEETINGS,
        path: meetingId,
        onSuccess: (response) => {
          const meeting = response.data;
          registrationForm = meeting.registration_form;

          // for the TimePicker component
          const formattedMeetingTime = dayjs(meeting.date)
            .set("hour", dayjs(meeting.time, "h:mm A").hour())
            .set("minute", dayjs(meeting.time, "h:mm A").minute())
            .format("YYYY-MM-DDTHH:mm");

          setMeetingType(meeting.meeting_type);
          setMeetingName(meeting.meeting_name);
          setMeetingDescription(meeting.description);
          setModerators(meeting.moderators);
          setParticipants(meeting.participants);
          setWatermark(meeting.watermark);
          setDate(dayjs(meeting.date));
          setTime(dayjs(formattedMeetingTime));
          setTimezone(meeting.timezone);
          setDuration(meeting.duration);
          setDurationMode(meeting.duration_mode);
          setIsRegistrationForm(meeting.registration_enabled);
          setUniqueFieldName(meeting.unique_field_name);
        },
        onFailure: (error) => {
            console.log("Unable to fetch meeting details!")
        }
      })
    }
  
    return () => {}
  }, [meetingId, isEdit])

  
  if (isLoading) {
    return (
      <div
        className="meeting-container"
        style={{
          display: "grid",
          placeContent: "center",
          height: "270px",
        }}
      >
        <Bars
          height="60"
          width="60"
          color="#959595"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="meeting-container">
      <p className="meeting-title">Tell us about your meeting</p>
      <p
        className="label-text"
        style={{ marginRight: "3px", marginBottom: "0" }}
      >
        Meeting type:
      </p>
      <FormControl sx={{ marginLeft: "16px", marginBottom: "16px" }}>
        <RadioGroup
          value={meetingType}
          onChange={(e) => setMeetingType(e.target.value)}
        >
          <FormControlLabel
            value="conference"
            control={<Radio />}
            label="Conference"
            checked={meetingType === "conference"}
          />
          <FormControlLabel
            value="live_streaming"
            control={<Radio />}
            label="Live Streaming"
            checked={meetingType === "live_streaming"}
          />
        </RadioGroup>
      </FormControl>

      <MeetingNameAndDescription
        meetingType="Conference"
        meetingName={meetingName}
        setMeetingName={setMeetingName}
        meetingDescription={meetingDescription}
        setMeetingDescription={setMeetingDescription}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          gap: "18px",
          margin: "18px 0",
          flexDirection: window.matchMedia("(max-width: 840px)").matches ? "column" : "row"
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date"
            value={date}
            onChange={(newValue) => {
              setDate(newValue)
            }}
          />
          <TimePicker
            label="Time"
            minutesStep={15}
            value={time}
            onChange={(newValue) => {
              setTime(newValue);
            }}
          />
        </LocalizationProvider>

        <FormControl sx={{ m: 1, minWidth: 200, margin: 0 }}>
          <InputLabel id="timezone">Time zone</InputLabel>
          <Select
            value={timezone}
            label="time zone"
            onChange={(e) => setTimezone(e.target.value)}
          >
            {timeZonesData?.length &&
              timeZonesData.map((timeZone) => (
                <MenuItem value={timeZone}>{timeZone}</MenuItem>
              ))}
          </Select>
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 115, margin: 0 }}>
          <TextField
            label="Duration"
            variant="outlined"
            id="duration"
            type="text"
            placeholder="duration"
            defaultValue={duration}
            onBlur={(e) => setDuration(e.target.value)}
          />
        </FormControl>

        <FormControl sx={{ m: 1, minWidth: 115, margin: 0 }}>
          <InputLabel id="hr/min">hr/min</InputLabel>
          <Select
            value={durationMode}
            label="hr/min"
            onChange={(e) => setDurationMode(e.target.value)}
          >
            {["hr", "min"].map((mode) => (
              <MenuItem value={mode}>{mode}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <ModeratorsAndParticipants
        moderators={moderators}
        setModerators={setModerators}
        participants={participants}
        setParticipants={setParticipants}
      />

      <div className="registration-form-checkbox-wrapper">
        <label htmlFor="registration-form">Registration Form</label>
        <Checkbox
          id="registration-form"
          checked={isRegistrationForm}
          onChange={(e) => setIsRegistrationForm(e.target.checked)}
          inputProps={{ "aria-label": "controlled" }}
        />
      </div>
      {isRegistrationForm && (
        <>
          <FormBuilder
            id={registrationFormId}
            formData={JSON.parse(registrationForm)}
          />
          <FormControl
            sx={{ m: 1, width: "100%", margin: 0, marginTop: "20px" }}
          >
            <InputLabel id="uniqueFieldName">
              Unique identifier field name
            </InputLabel>
            <Select
              value={uniqueFieldName}
              label="Unique identifier field name"
              onChange={(e) => setUniqueFieldName(e.target.value)}
            >
              {registrationFormFields?.length &&
                registrationFormFields.map((field) => (
                  <MenuItem value={field}>{field}</MenuItem>
                ))}
            </Select>
          </FormControl>
        </>
      )}

      <Watermark
        activeClientId={User.active_client_id}
        watermark={watermark}
        setWatermark={setWatermark}
      />

      <Button onClick={onSubmit} variant="contained" sx={{ marginTop: "24px" }}>
        {isEdit ? "Re-":""}Schedule Conference
      </Button>
    </div>
  );
}
